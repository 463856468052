<template>
  <div class="login">
    <aside class="login-aside"></aside>
    <aside class="login-box-my">
      <div class="login-container">
        <div class="login-title">
          <article>智慧零售SAAS系统</article>
          <article>平台端</article>
        </div>
        <div class="login-input">
          <article class="login-label">用户名</article>
          <el-input
            v-model.trim="username"
            placeholder="请输入用户名"
            clearable>
          </el-input>
        </div>
        <div style="margin-top:25px;">
          <article class="login-label">密码</article>
          <el-input
            v-model.trim="password"
            placeholder="请输入密码"
            clearable
            show-password>
          </el-input>
        </div>
        <div style="margin-top:45px;">
          <el-button type="primary" style="width:100%;font-size:1.8vh;" @click="onLogin()">登&nbsp;&nbsp;录</el-button>
        </div>
        <!-- <div style="text-align: right;margin-top:10px;">
          <a href="#" style="color: #9ea7b4;">忘记密码?</a>
        </div> -->
      </div>
    </aside>
  </div>
</template>
<script>
export default {
  data() {
    return {
      username: "",
      password: "",
    }
  },
  mounted: function () {
    window.addEventListener("keydown", this.enterKeyEvent)
  },
  methods: {
    enterKeyEvent(e) {
      if (e.keyCode === 13) {
        this.onLogin()
      }
    },
    onLogin: function () {
      let that = this
      // return
      if (!this.username) {
        this.$message.warning('登录账号不能为空');
        return false;
      }
      if (!this.password) {
        this.$message.warning('密码不能为空');
        return false;
      }

      let url = "/api/login";
      let data = {
        username: this.username,
        password: this.password,
        platform_type: 0,
        client_id: "clientid",
        grant_type: "password"
      };
      $.ajax({
        url: `${requestUrl}${url}`,
        data: data,
        type: "POST",
        success: (res) => {
          localStorage.adminToken = res.access_token;
          localStorage.adminRefreshToken = res.refresh_token;
          window.location.replace("/")
        },
        error(err) {
          console.log(err.responseText)
          let str = JSON.parse(err.responseText)
          console.log(str['error_description'])
          that.$message.error(str['error_description'])
        }
      })
    }
  }
}
</script>
<style scoped>
/deep/ .el-button--primary{
  background-color: #5FB85C;
  border-color: #5FB85C;
}
/deep/ .el-button--primary:focus, /deep/ .el-button--primary:hover{
  background-color: #5FB85C;
  border-color: #5FB85C;
}
/deep/ .el-input--suffix .el-input__inner{
  padding: 0 5px;
}
.login {
  width: 100vw;
  height: 100vh;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.login-aside{
  width: 65%;
  height: 75%;
  background: url(../../public/images/login-bg.png) no-repeat center 0;
  background-size: contain;
}
.login-box-my {
  width: 35%;
  /* position: fixed;
  top: 50%;
  right: 20%;
  transform: translateY(-50%); */
  padding: 10px 40px 30px 40px;
  background-color: white;
  border-radius: 7px;
}
.login-container{
  width: 70%;
  /* margin: 0 auto; */
}
.login-title{
  margin-bottom: 10px;
  color: #5FB85C;
  font-size: 4.63vh;
  font-weight: bold;
  font-family: Microsoft YaHei;
}
.login-input{
  margin-top: 5vh;
  color: #1A1A1A;
}
.login-label{
  margin: 5px 0;
  padding-left: 5px;
  color: #1A1A1A;
  font-size: 1.8vh;
}
</style>
