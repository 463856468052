<template>
  <div class="home">
    <div class="wrapper">
      <header class="main-header">
        <a href="#" class="logo">
          <span class="logo-mini">
            <a href="#" class="mdi mdi-view-sequential" data-toggle="offcanvas" role="button" id="menu" ref="btn" style="color: #fff;">
              <span class="sr-only">Toggle navigation</span>
            </a>
          </span>
          <section class="logo-lg">
            <article style="display: flex;justify-content: space-around;align-items: center;">
              <span>智慧零售SAAS系统平台端</span>
              <span class="logo-type"></span>
              <a href="#" class="mdi mdi-view-sequential" data-toggle="offcanvas" role="button" id="menu" ref="btn" style="margin-left: 5px;color: #fff;">
                <span class="sr-only">Toggle navigation</span>
              </a>
            </article>
          </section>
        </a>
      </header>
      <aside class="main-sidebar">
        <section class="sidebar">
          <ul class="sidebar-menu">
            <!-- <li class="active">
              <a class="multitabs" href="main.html" data-content="false">
                <i class="my-menu mdi mdi-home"></i>
                <span> 首页</span>
              </a>
            </li> -->

            <li class="treeview" v-for="v in menuList" :key="v.id">
              <a href="#" class="main-item">
                <i class="my-menu mdi" :class="v.pictureUrl"></i>
                <span v-if="v.children.length">{{ v.menuName }}</span>
                <span v-else ><a :href="v.menuCode + '.html'" class="multitabs" data-content="false">{{ v.menuName }}</a></span>
                <i class="mdi mdi-chevron-down pull-right" v-if="v.children.length" style="margin-top: 6px;"></i>
              </a>
              <ul class="treeview-menu">
                <li v-for="p in v.children" :key="p.id">
                  <a class="multitabs" :href="p.menuCode + '.html'" data-content="false" style="margin-left: 10px;">
                    <!-- <i class="mdi mdi-chevron-double-right"></i> -->
                    {{ p.menuName }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </section>
      </aside>
      <div class="navbar-custom-menu" style="position: fixed; top: 0; right: 0; z-index: 11;">
        <ul class="nav navbar-nav">
          <li class="dropdown user user-menu">
            <a href="#" class="dropdown-toggle" data-toggle="dropdown"
              style="display: flex; justify-content: space-between;align-items: center;">
              <img src="/images/user-icon.png" class="user-image" alt="User Image" />
              <span class="hidden-xs">{{ account }}</span>
              <i class="mdi mdi-menu-down"></i>
            </a>
            <ul class="dropdown-menu">
              <li class="user-header">
                <img src="/images/user-icon.png" class="img-circle" alt="User Image" />
                <p style="color: #263238;">{{ accountName }}</p>
              </li>
              <li class="user-body">
                <div class="row">
                  <div class="col-xs-4 text-center">
                    <span>{{ account }}</span>
                  </div>
                  <div class="col-xs-4 text-center">
                    <span class="role-type">{{ isSystemRole ? "管理员" : roles }}</span>
                  </div>
                  <div class="col-xs-4 text-center">
                    <span>{{ accountName }}</span>
                  </div>
                </div>
              </li>
              <li class="user-footer">
                <div class="pull-left">
                  <a href="#" class="btn btn-default btn-flat" v-on:click="onRestShow()">修改密码</a>
                </div>
                <div class="pull-right">
                  <a href="#" class="btn btn-default btn-flat" v-on:click="goLogin()">退出登录</a>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="content-wrapper" id="iframe-content"></div>

      <footer class="main-footer">
        <div class="pull-right hidden-xs"></div>
        <strong>Copyright &copy; 2017-2023 <span>未来鲜森</span>.</strong> All rights reserved.
      </footer>
    </div>
    <el-dialog title="修改密码" :visible.sync="dialogFormVisible">
      <el-form :model="form">
        <section class="password-dialog">
          <article class="form-label"><span class="form-require">*</span>旧密码</article>
          <el-form-item prop="oldPassword" :rules="[
            { required: true, message: '请输入旧密码', trigger: 'blur' },
          ]">
            <el-input v-model="form.oldPassword" autocomplete="off" show-password class="form-item-content">
            </el-input>
          </el-form-item>
        </section>

        <section class="password-dialog">
          <article class="form-label"><span class="form-require">*</span>新密码</article>
          <el-form-item prop="newPassword" :rules="[
            { required: true, message: '请输入新密码', trigger: 'blur' },
          ]">
            <el-input v-model="form.newPassword" autocomplete="off" show-password></el-input>
          </el-form-item>
        </section>

        <section class="password-dialog">
          <article class="form-label"><span class="form-require">*</span>确认新密码</article>
          <el-form-item prop="newPassword2" :rules="[
            { required: true, message: '请输入新密码', trigger: 'blur' },
          ]">
            <el-input v-model="form.newPassword2" autocomplete="off" show-password></el-input>
          </el-form-item>
        </section>

      </el-form>
      <div slot="footer" class="dialog-footer" style="margin-bottom: 20px;">
        <el-button type="primary" @click="onRestPassword()">确 定</el-button>
        <el-button @click="dialogFormVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: 'Home',
  data() {
    return {
      dialogFormVisible: false,
      department: {},
      isSystemRole: 0,
      roles: "",
      form: {
        oldPassword: '',
        newPassword: '',
        newPassword2: ''
      },
      dialogFormVisible: false,
      menuList: [],
      tenantType: 2, //1公司，2分公司
      account: '',
      accountName: ''
    }
  },
  mounted: function () {
    this.getInfo();
    this.getDeviceTypeList();
    setTimeout(() => {
      document.querySelector("#menu").click();
    }, 100);
  },
  methods: {
    getInfo: function () {
      //获取用户信息
      let url = "/Api/URPM/V1/User/Profile";
      POSTAUTHOUR(url, {}).then((res) => {
        this.name = res.name;
        for (const item of res.roles) {
          this.roles += `${item.name},`;
        }
        this.isSystemRole = res.isSystemRole;
        this.account = res.account;
        this.accountName = res.name;
        this.department = res.department;
        localStorage.setItem('userInfoAccount', res.name)
        localStorage.isSystemRole = this.isSystemRole;
        // this.tenantType = res.tenant.tenantType;
        if (this.isSystemRole) {
          this.getMenuList();
        } else {
          this.getUserMenu();
        }
      });
    },
    getUserMenu: function () {
      this.menuList = [];
      //获取用户权限
      let funList = [];
      let url = "/Api/URPM/V1/User/Menu";
      POSTAUTHOUR(url, { platformType: 0 }).then((res) => {
        //设置功能菜单列表
        for (let item of res) {
          item.children = item.childMenus || [];
          for (const c of item.children) {
            for (const fun of c.menuPermissions) {
              let str = `${c.menuCode}${fun.code}`;
              funList.push(str);
            }
          }
        }
        this.menuList = res;
        localStorage.menuList = JSON.stringify(this.menuList);
        localStorage.funList = JSON.stringify(funList);
        let defaultTitle = '';
        let defaultUrl = '';
        if(this.menuList[0].children && this.menuList[0].children.length) {
          defaultTitle = this.menuList[0].children[0].menuName;
          defaultUrl = this.menuList[0].children[0].menuCode + '.html';
        } else {
          defaultTitle = this.menuList[0].menuName;
          defaultUrl = this.menuList[0].menuCode + '.html';
        }
        parent.$(parent.document).data("multitabs").create({
          iframe: true,
          title: defaultTitle,
          url: defaultUrl,
          type: 'main'
        }, true);
      });
    },
    getMenuList: function () {
      //获取所有菜单
      let funList = [];
      let funStr = "";
      let url = "/Api/URPM/V1/menu/list/all";
      POSTAUTHOUR(url, { applicationId: 0 }).then((res) => {
        this.menuList = [];
        for (let item of res) {
          if (this.tenantType == 2 && item.menuName == "分公司管理") {
            continue;
          }
          if (!item.fatherId) {
            item.children = [];
            this.menuList.push(item);
          }
        }
        let deleteArr = []
        this.menuList.forEach((item, index) => {
          if (item['menuName'] === '会员列表') {
            deleteArr.push(index)
          }
          if (item['menuName'] === '移动库存') {
            deleteArr.push(index)
          }
        })
        if (deleteArr.length !== 0) {
          deleteArr.forEach((item) => {
            this.menuList.splice(item, 1)
          })
        }
        for (const m of this.menuList) {
          for (const item of res) {
            if (m.id == item.fatherId) {
              m.children.push(item);
              for (const fun of item.menuPermissions) {
                funStr = `${item.menuCode}${fun.code}`;
                funList.push(funStr);
              }
            }
          }
        }
        localStorage.menuList = JSON.stringify(this.menuList);
        localStorage.funList = JSON.stringify(this.funList);
        // this.$root.funList = funList;
        let defaultTitle = '';
        let defaultUrl = '';
        if(this.menuList[0].children && this.menuList[0].children.length) {
          defaultTitle = this.menuList[0].children[0].menuName;
          defaultUrl = this.menuList[0].children[0].menuCode + '.html';
        } else {
          defaultTitle = this.menuList[0].menuName;
          defaultUrl = this.menuList[0].menuCode + '.html';
        }
        parent.$(parent.document).data("multitabs").create({
          iframe: true,
          title: defaultTitle,
          url: defaultUrl,
          type: 'main'
        }, true);
      });
    },
    onRestPassword() {
      //修改密码
      // let rule = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/
      // let pass = new RegExp(rule)
      if (!this.form.oldPassword) {
        this.$message.warning("请输入旧密码！");
        return false;
      }
      if (!this.form.newPassword) {
        this.$message.warning("请输入新密码！");
        return false;
      }
      if (this.form.newPassword !== this.form.newPassword2) {
        this.$message.warning("新密码与确认密码不相同！");
        return false;
      }

      // if (pass.test(this.form.newPassword)) {
      let url = "/Api/URPM/V1/user/ModifyPassword";
      let data = {
        oldPassword: this.form.oldPassword,
        newPassword: this.form.newPassword,
      };
      POSTAUTHOUR(url, data).then((res) => {
        this.dialogFormVisible = false;
        this.$message.success("修改成功！");
        // parent.root.resetToken();
        this.goLogin();
      }).catch(error => {
        this.$message.error(error.errorMessage || "修改失败！");
      })
      // } else {
      //   this.$message.error('新密码要求8-16位，必须包含大小写字母和数字，不可输入特殊字符和空格')
      // }

    },
    goLogin: function () {
      localStorage.adminToken = "";
      localStorage.adminRefreshToken = "";
      // window.location.replace("/login");
      // this.$router.replace("/login");
      this.$router.replace({ path: "/login" });
    },
    goPage: function () {
      let url = encodeURI("/page.html#/ProductMZ");
      parent.$(parent.document).data('multitabs').create({
        iframe: true,
        title: "添加云商品",
        url: url
      }, true);
    },
    onRestShow: function () {
      this.dialogFormVisible = true;
      this.form = { oldPassword: "", newPassword: "", newPassword2: "" };
    },
    //设备类型枚举列表
    getDeviceTypeList: function () {
      let url = "/Api/device/V1/deviceEnum/FindDeviceTypeEnumList";
      let data = {};
      POSTAUTHOUR(url, data).then(res => {
        localStorage.deviceTypeList = JSON.stringify(res);
      })
    },

  },
  components: {}
}
</script>
<style>
.mt-wrapper {
  height: calc(100vh - 40px) !important;

}

.main-footer {
  padding: 10px;
}

.my-menu {
  font-size: 18px;
  position: relative;
  left: -3px;
  top: 3px;
}

.mdi-chevron-double-right {
  position: relative;
  top: 2px;
  left: -4px;
  margin-right: 4px;
}

.mdi-chevron-left {
  position: relative;
  top: 6px;
}

.password-dialog{
  width: 80%;
  margin: 0 auto 20px auto;
}
.main-item:hover a{
  color: #fff!important;
}
</style>
